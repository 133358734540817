import React from "react";
import Tuits from "../tuits";

function Bookmarks () {
  return(
    <div>
        <h1>Bookmarks Screen</h1>
      <Tuits/>
    </div>
  );
}
export default Bookmarks;