/**
    Component of showing the tuits and replies related to the logged in user.
*/

const TuitsAndReplies = () => {
    return(
        <div>
            <h1>Tuits and Replies</h1>
        </div>
    );
};
export default TuitsAndReplies;