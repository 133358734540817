/**
    Renders components of the home tab in the tuiter app.
*/
import React from "react";
import Tuits from "../tuits";
import * as service from "../../services/tuits-service";
import {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import * as login from "../../services/auth-service";
import * as privilege from "../../services/admin-service";

const Home = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [profile, setProfile] = useState({});
  const [access, setAccess] = useState({});

  useEffect(async () => {
try{
      const user = await login.profile();
      setProfile(user);
      privilege.getPrivelageAccess().then((data) => {
        data.map(res => {
          if(res.user._id == user._id){
            setAccess(res);
          }
        })
      });
      
  } catch (e) {
   // navigate('/login');
  }
  }, []);
  console.log({access})
  const {uid} = useParams();
  const [tuits, setTuits] = useState([]);
  const [tuit, setTuit] = useState('');
  const userId = uid;
  const findTuits = () =>
      service.findAllTuits()
        .then(tuits => setTuits(tuits));
  useEffect(() => {
    let isMounted = true;
    findTuits()
    return () => {isMounted = false;}
  }, []);
  const createTuit = () =>
      service.createTuit('my', {tuit})
          .then(findTuits)
  return(
    <div className="ttr-home">
      <div className="border border-bottom-0">
        <h4 className="fw-bold p-2">Home Screen</h4>
        <div className="d-flex">
          <div className="p-2">
            <img async className="ttr-width-50px rounded-circle"
                 src={profile.profilePhoto}/>
          </div>
          <div className="p-2 w-100">
            <textarea
                onChange={(e) =>
                    setTuit(e.target.value)}
              placeholder="What's happening?"
              className="w-100 border-0"></textarea>
            <div className="row">
              <div className="col-10 ttr-font-size-150pc text-primary">
                <i className="fas fa-portrait me-3"></i>
                <i className="far fa-gif me-3"></i>
                <i className="far fa-bar-chart me-3"></i>
                <i className="far fa-face-smile me-3"></i>
                <i className="far fa-calendar me-3"></i>
                <i className="far fa-map-location me-3"></i>
              </div>
              { access.allowTuits === true ? 
              <div className="col-2">
                <a onClick={createTuit}
                   className={`btn btn-primary rounded-pill fa-pull-right
                                fw-bold ps-4 pe-4`}>
                  Tuit
                </a>
              </div>
              : <div className="col-2">
              <a onClick={() => navigate('/login')} className={`btn btn-primary rounded-pill fa-pull-right
                                fw-bold ps-4 pe-4`}>
                Login
              </a>
            </div>}
            </div>
          </div>
        </div>
      </div>
      <Tuits tuits={tuits}
             refreshTuits={findTuits}/>
    </div>
  );
};
export default Home;